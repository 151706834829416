import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { Table, Button } from 'antd'
import './Upload.css'

const Upload = () => {

    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])

    const [isFileUploadEnabled, setisFileUploadEnabled] = useState(true)

    const [isRedirecting, setIsRedirecting] = useState(false)
    const [redirectPath, setRedirectPath] = useState('')

    useEffect(() => {
        if (localStorage.getItem("headers") !== null && localStorage.getItem("data") !== null) {
            let prevData = JSON.parse(localStorage.getItem("data"))
            let prevHeaders = JSON.parse(localStorage.getItem("headers"))
            processColumns(prevHeaders)
            if (prevData.length > 0) {
                setData(prevData)
                setisFileUploadEnabled(false)
            }
        }
    }, [])

    const redirectToAnalytics = (text) => {
        let username = text
        localStorage.setItem('username', username)
        setIsRedirecting(prevState => ({
            ...prevState,
            isRedirecting: true
        }))
        setRedirectPath(prevState => ({
            ...prevState,
            redirectPath: '/visualizations',
        }))
    }

    // process CSV data
    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/)
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
        localStorage.setItem('headers', JSON.stringify(headers))

        const list = []
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
            if (headers && row.length === headers.length) {
                const obj = {}
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j]
                    if (d.length > 0) {
                        if (d[0] === '"')
                            d = d.substring(1, d.length - 1)
                        if (d[d.length - 1] === '"')
                            d = d.substring(d.length - 2, 1)
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    obj.key = i
                    list.push(obj)
                }
            }
        }
        localStorage.setItem('data', JSON.stringify(list))
        setData(list)
        processColumns(headers)
    }

    const processColumns = (headers) => {
        // prepare columns list from headers
        const columns = headers.map((item) => {
            if (item === "name") {
                return {
                    render: text => <div onClick={() => redirectToAnalytics(text)} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0336FF' }}>{text}</div>,
                    title: item.toUpperCase(),
                    dataIndex: item,
                    key: item
                }
            }
            return {
                title: item.toUpperCase(),
                dataIndex: item,
                key: item
            }
        })
        setColumns(columns)
    }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0]
        const filename = file.name.split(".")
        const fileType = filename[1]
        if (fileType === 'csv' || fileType === 'xlsx' || fileType === 'xls') {
            const reader = new FileReader()
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result
                const wb = XLSX.read(bstr, { type: 'binary' })
                /* Get first worksheet */
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
                processData(data)
            }
            reader.readAsBinaryString(file)
            setisFileUploadEnabled(false)
        } else {
            window.alert('Please upload CSV or XLSX or XLS type files. Other file types are not allowed.')
        }
    }

    // reset the table
    const resetTable = () => {
        localStorage.setItem('data', JSON.stringify([]))
        localStorage.setItem('headers', JSON.stringify([]))
        localStorage.setItem('username', "")
        setColumns([])
        setData([])
        setisFileUploadEnabled(true)
    }

    if (isRedirecting) {
        return (
            <Redirect push to={{ 
                pathname: redirectPath.redirectPath,
             }} />
        )
    } else {
        return (
            <div className="upload__container">
                {data?.length > 0 && 
                    <Button
                        size={'small'}
                        type="dashed"
                        onClick={resetTable}
                        style={{ borderColor: "#0336FF", color: "#0336FF" }}>
                        RESET TABLE
                    </Button>
                }
                <div className={data?.length > 0 ? "upload__wrapper upload_data_style" : "upload__wrapper"}>
                    {data?.length === 0 ?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div className="content-upload">
                                <div className="content-upload-title">Upload File</div>
                                <div className="content-upload-subtitle">or drag and drop your file into this box.</div>
                                <input className="content-upload-file"
                                    type="file" accept=".csv,.xlsx,.xls" 
                                    name="fileUpload" id="fileUpload"
                                    onChange={handleFileUpload}
                                    disabled={!isFileUploadEnabled} />
                                <label for="fileUpload">
                                    <div className="content-upload-file-btn">BROWSE</div>
                                </label>
                                <div className="content-upload-subtitle">Acceptable file type:{"\n"}CSV, XLSV, XLS</div>
                            </div>
                        </div>
                            :
                        <Table
                            dataSource={data}
                            columns={columns}
                            locale={{ emptyText: "There is no data to display." }}
                            pagination={{ position: 'bottomRight' }}
                            size="small"
                        />
                    }
                </div>
            </div>
        )
    }
}
export default Upload