import Layout from "../../layouts/Layout"
import Upload from "../Upload"

const UploadPage = () => {
    return (
        <Layout>
            <Upload />
        </Layout>
    )
}
export default UploadPage