import React, { useEffect, useState } from 'react'
import CustomizedBarChart from "./CustomizedBarChart"
import './Games.css'

const Games = ({ className, xAxisDataKey, barDataKey, filteredData, data }) => {

    const menuItemSelectedClassName = 'visualizations__menu-items-selected'
    const menuItemNotSelectedClassName = 'visualizations__menu-items'

    const [gamesList, setGamesList] = useState([
        {
            name: 'GM1',
            isSelected: false
        },
        {
            name: 'GM2',
            isSelected: false
        },
        {
            name: 'GM3',
            isSelected: false
        },
        {
            name: 'GM4',
            isSelected: false
        },
        {
            name: 'GM5',
            isSelected: false
        },
        {
            name: 'GM6',
            isSelected: false
        },
        {
            name: 'GM7',
            isSelected: false
        },
        {
            name: 'GM8',
            isSelected: false
        },
        {
            name: 'GM9',
            isSelected: false
        },
        {
            name: 'GM10',
            isSelected: false
        },
        {
            name: 'GM11',
            isSelected: false
        },
        {
            name: 'GM12',
            isSelected: false
        },
        {
            name: 'GM13',
            isSelected: false
        },
        {
            name: 'GM14',
            isSelected: false
        },
        {
            name: 'GM15',
            isSelected: false
        },
    ])
    const [gameNames, setGameNames] = useState(['GM1', 'GM2', 'GM3', 'GM4', 'GM5', 'GM6', 'GM7', 'GM8', 'GM9', 'GM10', 'GM11', 'GM12', 'GM13', 'GM14', 'GM15'])

    const [filteredGameData, setFilteredGameData] = useState([])
    const [accumulativeFilteredGameData, setAccumulativeFilteredGameData] = useState([])

    const [isCummulativeGameVisible, setIsCummulativeGameVisible] = useState(true)

    const [singleGameData, setSingleGameData] = useState([])

    useEffect(() => {
        const accumulativeFilteredGameData = []
        const filteredGameData = []
        let requiredKeyValue = {}
        let filteredKeys
        let requiredKeys
        if (filteredData.length > 0) {
            filteredData.filter((data) => {
                filteredKeys = Object.keys(data)
                requiredKeys = filteredKeys.filter(item => gameNames.includes(item))
                requiredKeyValue = Object.keys(data)
                    .filter(key => requiredKeys.includes(key))
                    .reduce((obj, key) => {                        
                        obj[key] = data[key] === "Not Attempted" ? 0 : parseInt(data[key])
                        return obj
                    }, {})
                requiredKeys.map(key => {
                    let obj = {}
                    let temp = data['date'].split(" ")
                    let dateString = temp[0].replace("-", "/").replace("-", "/")
                    obj.date = dateString.toLocaleString()
                    obj.name = key
                    obj.value = requiredKeyValue[key]
                    accumulativeFilteredGameData.push(obj)
                })
            })
            setAccumulativeFilteredGameData(accumulativeFilteredGameData)
            var holder = {}
            accumulativeFilteredGameData.forEach(function (d) {
                if (holder.hasOwnProperty(d.name)) {
                    holder[d.name] = holder[d.name] + d.value
                } else {
                    holder[d.name] = d.value
                }
            })
            for (var prop in holder) {
                filteredGameData.push({ name: prop, value: holder[prop] })
            }
            setFilteredGameData(filteredGameData)
        }
    }, [filteredData, data])

    const handleGameSelection = (selectedIndex) => {
        let newArr = [...gamesList]
        newArr.map((item, index) => {
            if (index === selectedIndex) {
                item.isSelected = true
                processSingleGame(item.name)
            } else {
                item.isSelected = false
            }
        })
        setGamesList(newArr)
        setIsCummulativeGameVisible(false)
    }

    const processSingleGame = (selectedGame) => {
        let singleGameData = []
        accumulativeFilteredGameData.map((item, index) => {
            if (item.name === selectedGame) {
                singleGameData.push(item)
            }
        })
        setSingleGameData(singleGameData)
    }

    return (
        <div className={className}>
            <div className="games__tab">
                {gamesList.length > 0 && gamesList.map((item, index) => {
                    return (
                        <div key={index} className={item.isSelected ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleGameSelection(index)}>{item.name}</div>
                    )
                })}
            </div>
            {isCummulativeGameVisible && <CustomizedBarChart
                data={filteredGameData}
                xAxisDataKey={xAxisDataKey}
                barDataKey={barDataKey}
            />}
            {!isCummulativeGameVisible && <CustomizedBarChart
                data={singleGameData}
                xAxisDataKey={"date"}
                barDataKey={"value"}
            />}
        </div>
    )
}
export default Games