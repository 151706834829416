import React, { useState, useEffect } from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import './Visualizations.css'
import Games from './Games'
import Analytics from './Analytics'

const Visualizations = () => {
    const [data, setData] = useState([])
    const [selectedUsername, setSelectedUsername] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [userObj, setUserObj] = useState({})
    const [showUserDetails, setShowUserDetails] = useState(false)

    const [isGamesVisible, setIsGamesVisible] = useState(true)
    const [isAnalyticsVisible, setIsAnalyticsVisible] = useState(false)

    const menuItemSelectedClassName = 'visualizations__menu-items-selected'
    const menuItemNotSelectedClassName = 'visualizations__menu-items'

    const [isRedirecting, setIsRedirecting] = useState(false)
    const [redirectPath, setRedirectPath] = useState('')

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem("data"))
        let username = localStorage.getItem("username")
        let userObj = {}
        if (data.length === 0) {
            setIsRedirecting(true)
            setRedirectPath('/')
        } else {
            setData(data)
            setSelectedUsername(username)
            const filteredData = data.map((item, index) => {
                if (item.name === username) {
                    userObj.name = item.name
                    userObj.age = item.age
                    userObj.gender = item.gender
                    userObj.medicalCondition = item.issue
                    return item
                }
            })
            console.log(userObj)
            setUserObj(userObj)
            setFilteredData(filteredData)
        }
    }, [])

    const handleVisibleChange = (value) => {
        if (value === 'games') {
            setIsGamesVisible(true)
            setIsAnalyticsVisible(false)
        } else {
            setIsGamesVisible(false)
            setIsAnalyticsVisible(true)
        }
    }

    const handleUserDetailVisibility = () => {
        setShowUserDetails(!showUserDetails)
    }

    return (
        <div className="visualizations__container">
            <div className="visualizations__tab">
                <div className={isGamesVisible ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleVisibleChange('games')}>Games</div>
                <div className={isAnalyticsVisible ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleVisibleChange('analytics')}>Analytics</div>
            </div>
            <div className="visualizations__user-profile" onClick={handleUserDetailVisibility}>
                <Avatar size={40} style={{ backgroundColor: '#FF0266' }} icon={<UserOutlined />} />
                <div className="visualizations__user-name">{selectedUsername}</div>
            </div>
            {showUserDetails && <div className="visualizations__user-profile-detail">
                <div className="visualizations__user-name">{userObj.name}</div>
                <div >Age: {userObj.age}</div>
                <div >Gender: {userObj.gender}</div>
                <div >Medical Condition: {userObj.medicalCondition}</div>
            </div>}
            {isGamesVisible && <Games
                className="visualizations__games"
                filteredData={filteredData}
                data={data}
                xAxisDataKey={"name"}
                barDataKey={"value"}
            />}
            {isAnalyticsVisible && <Analytics
                className="visualizations__analytics"
                filteredData={filteredData}
                data={data}
                xAxisDataKey={"name"}
                barDataKey={"value"}
            />}
        </div>
    )
}
export default Visualizations