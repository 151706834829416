import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

const CustomizedBarChart = ({ data, xAxisDataKey, barDataKey }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" >
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisDataKey} />
                <YAxis />
                <Tooltip />
                <Bar dataKey={barDataKey} fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default CustomizedBarChart