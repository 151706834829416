import Layout from "../../layouts/Layout"
import Visualizations from "../Visualizations"

const VisualizationsPage = () => {
    return (
        <Layout>
            <Visualizations />
        </Layout>
    )
}
export default VisualizationsPage