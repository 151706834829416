import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import './Layout.css'
import home from './../assets/images/home.png'
import appName from './../assets/images/app_name.png'

const Layout = (props) => {

    const [isRedirecting, setIsRedirecting] = useState(false)

    const [redirectPath, setRedirectPath] = useState('')

    const redirectToUpload = () => {
        setIsRedirecting(prevState => ({
            ...prevState,
            isRedirecting: true
        }))
        setRedirectPath(prevState => ({
            ...prevState,
            redirectPath: '/'
        }))
    }

    if (isRedirecting) {
        return (
            <Redirect push to={{ pathname: redirectPath.redirectPath }} />
        )
    } else {
        return (
            <div className="layout__container">
                <header>
                    <div className="layout__heading">
                        {/* Trana Visualizer */}
                        <img src={appName} alt="app name" />
                    </div>
                    {window.location.href.includes("visualizations") && 
                        <div className="layout__upload" onClick={redirectToUpload}>
                            <img src={home} alt="home" />
                        </div>
                    }
                </header>
                <body>
                    {props.children}
                </body>
                {/* <footer>
                    <div>
                        &#169; 2021 X Y Z Limited. All Rights Reserved.
                    </div>
                </footer> */}
            </div>
        )
    }
}
export default Layout