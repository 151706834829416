import './App.css';
import { Switch, Route } from 'react-router-dom'
import UploadPage from './components/Pages/UploadPage'
import VisualizationsPage from './components/Pages/VisualizationsPage'

function App() {
  return (
    <Switch>
      <Route exact path="/" component={UploadPage} />
      <Route path="/visualizations" component={VisualizationsPage} />
    </Switch>
  );
}

export default App;
