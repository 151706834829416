import React, { useState, useEffect } from 'react'
import CustomizedBarChart from "./CustomizedBarChart"
import './Analytics.css'

const Analytics = ({ className, filteredData, data, xAxisDataKey, barDataKey }) => {

    const menuItemSelectedClassName = 'analytics__menu-items-selected'
    const menuItemNotSelectedClassName = 'analytics__menu-items'

    const [isUpperVisible, setisUpperVisible] = useState(true)
    const [isLowerVisible, setisLowerVisible] = useState(false)

    const [upperList, setUpperList] = useState([
        {
            name: 'UL',
            isSelected: false
        },
        {
            name: 'UM',
            isSelected: false
        },
        {
            name: 'UR',
            isSelected: false
        },
        {
            name: 'UF',
            isSelected: false
        },
        {
            name: 'UB',
            isSelected: false
        },
    ])
    const upperNames = ['UL', 'UM', 'UR', 'UF', 'UB']

    const [lowerList, setLowerList] = useState([
        {
            name: 'LL',
            isSelected: false
        },
        {
            name: 'LM',
            isSelected: false
        },
        {
            name: 'LR',
            isSelected: false
        },
        {
            name: 'LF',
            isSelected: false
        },
        {
            name: 'LB',
            isSelected: false
        },
    ])
    const lowerNames = ['LL', 'LM', 'LR', 'LF', 'LB']

    const [isCummulativeUpperVisible, setIsCummulativeUpperVisible] = useState(true)
    const [isCummulativeLowerVisible, setIsCummulativeLowerVisible] = useState(false)

    const [filteredUpperData, setFilteredUpperData] = useState([])
    const [accumulativeFilteredUpperData, setAccumulativeFilteredUpperData] = useState([])
    const [filteredLowerData, setFilteredLowerData] = useState([])
    const [accumulativeFilteredLowerData, setAccumulativeFilteredLowerData] = useState([])

    const [singleUpperData, setSingleUpperData] = useState([])
    const [singleLowerData, setSingleLowerData] = useState([])

    const [isSingleUpperVisible, setIsSingleUpperVisible] = useState(false)
    const [isSingleLowerVisible, setIsSingleLowerVisible] = useState(false)


    useEffect(() => {
        const accumulativeFilteredUpperData = []
        const accumulativeFilteredLowerData = []
        const filteredUpperData = []
        const filteredLowerData = []
        let requiredUpperKeyValue = {}
        let requiredLowerKeyValue = {}
        let filteredUpperKeys
        let filteredLowerKeys
        let requiredUpperKeys
        let requiredLowerKeys
        if (filteredData.length > 0) {
            filteredData.filter((data) => {
                filteredUpperKeys = Object.keys(data)
                filteredLowerKeys = Object.keys(data)
                requiredUpperKeys = filteredUpperKeys.filter(item => upperNames.includes(item))
                requiredLowerKeys = filteredLowerKeys.filter(item => lowerNames.includes(item))
                requiredUpperKeyValue = Object.keys(data)
                    .filter(key => requiredUpperKeys.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = parseInt(data[key])
                        return obj
                    }, {})
                requiredLowerKeyValue = Object.keys(data)
                    .filter(key => requiredLowerKeys.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = parseInt(data[key])
                        return obj
                    }, {})
                requiredUpperKeys.map(key => {
                    let obj = {}
                    let temp = data['date'].split(" ")
                    let dateString = temp[0].replace("-", "/").replace("-", "/")
                    obj.date = dateString.toLocaleString()
                    obj.name = key
                    obj.value = requiredUpperKeyValue[key]
                    accumulativeFilteredUpperData.push(obj)
                })
                requiredLowerKeys.map(key => {
                    let obj = {}
                    let temp = data['date'].split(" ")
                    let dateString = temp[0].replace("-", "/").replace("-", "/")
                    obj.date = dateString.toLocaleString()
                    obj.name = key
                    obj.value = requiredLowerKeyValue[key]
                    accumulativeFilteredLowerData.push(obj)
                })
            })
            setAccumulativeFilteredUpperData(accumulativeFilteredUpperData)
            setAccumulativeFilteredLowerData(accumulativeFilteredLowerData)
            var holder = {}
            accumulativeFilteredUpperData.forEach(function (d) {
                if (holder.hasOwnProperty(d.name)) {
                    holder[d.name] = holder[d.name] + d.value
                } else {
                    holder[d.name] = d.value
                }
            })
            for (var prop in holder) {
                filteredUpperData.push({ name: prop, value: holder[prop] })
            }
            setFilteredUpperData(filteredUpperData)
            var holder = {}
            accumulativeFilteredLowerData.forEach(function (d) {
                if (holder.hasOwnProperty(d.name)) {
                    holder[d.name] = holder[d.name] + d.value
                } else {
                    holder[d.name] = d.value
                }
            })
            for (var prop in holder) {
                filteredLowerData.push({ name: prop, value: holder[prop] })
            }
            setFilteredLowerData(filteredLowerData)
        }
    }, [filteredData, data])

    const handleUpperChange = (selectedIndex) => {
        let newArr = [...upperList]
        newArr.map((item, index) => {
            if (index === selectedIndex) {
                item.isSelected = true
                processSingleUpper(item.name)
            } else {
                item.isSelected = false
            }
        })
        setUpperList(newArr)
        setIsCummulativeUpperVisible(false)
    }

    const handleLowerChange = (selectedIndex) => {
        let newArr = [...lowerList]
        newArr.map((item, index) => {
            if (index === selectedIndex) {
                item.isSelected = true
                processSingleLower(item.name)
            } else {
                item.isSelected = false
            }
        })
        setLowerList(newArr)
        setIsCummulativeLowerVisible(false)
    }

    const handleVisibleChange = (value) => {
        if (value === 'upper') {
            setisUpperVisible(true)
            setisLowerVisible(false)
            setIsCummulativeUpperVisible(true)
            setIsCummulativeLowerVisible(false)
            setIsSingleUpperVisible(false)
            setIsSingleLowerVisible(false)
        } else {
            setisUpperVisible(false)
            setisLowerVisible(true)
            setIsCummulativeUpperVisible(false)
            setIsCummulativeLowerVisible(true)
            setIsSingleUpperVisible(false)
            setIsSingleLowerVisible(false)
        }
    }

    const processSingleUpper = (selectedUpper) => {
        let singleUpperData = []
        accumulativeFilteredUpperData.map((item, index) => {
            if (item.name === selectedUpper) {
                singleUpperData.push(item)
            }
        })
        setSingleUpperData(singleUpperData)
        setIsSingleUpperVisible(true)
        setIsSingleLowerVisible(false)
        setIsCummulativeUpperVisible(false)
        setIsCummulativeLowerVisible(false)
    }

    const processSingleLower = (selectedLower) => {
        let singleLowerData = []
        accumulativeFilteredLowerData.map((item, index) => {
            if (item.name === selectedLower) {
                singleLowerData.push(item)
            }
        })
        setSingleLowerData(singleLowerData)
        setIsSingleLowerVisible(true)
        setIsSingleUpperVisible(false)
        setIsCummulativeUpperVisible(false)
        setIsCummulativeLowerVisible(false)
    }


    return (
        <div className={className}>
            <div className="analytics__wrapper">
                <div className="analytics__tab">
                    <div className={isUpperVisible ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleVisibleChange('upper')}>Upper</div>
                    <div className={isLowerVisible ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleVisibleChange('lower')}>Lower</div>
                </div>
                {isUpperVisible && <div className="lower__tab">
                    {upperList.length > 0 && upperList.map((item, index) => {
                        return (
                            <div key={index} className={item.isSelected ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleUpperChange(index)}>{item.name}</div>
                        )
                    })}
                </div>}
                {isLowerVisible && <div className="lower__tab">
                    {lowerList.length > 0 && lowerList.map((item, index) => {
                        return (
                            <div key={index} className={item.isSelected ? menuItemSelectedClassName : menuItemNotSelectedClassName} onClick={() => handleLowerChange(index)}>{item.name}</div>
                        )
                    })}
                </div>}
                {isCummulativeUpperVisible && <CustomizedBarChart
                    data={filteredUpperData}
                    xAxisDataKey={xAxisDataKey}
                    barDataKey={barDataKey}
                />}
                {isSingleUpperVisible && <CustomizedBarChart
                    data={singleUpperData}
                    xAxisDataKey={"date"}
                    barDataKey={"value"}
                />}
                {isCummulativeLowerVisible && <CustomizedBarChart
                    data={filteredLowerData}
                    xAxisDataKey={xAxisDataKey}
                    barDataKey={barDataKey}
                />}

                {isSingleLowerVisible && <CustomizedBarChart
                    data={singleLowerData}
                    xAxisDataKey={"date"}
                    barDataKey={"value"}
                />}
            </div>
        </div>
    )
}
export default Analytics